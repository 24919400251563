import React from 'react';


const FacialsPerms = () => {
    return ( 
        <>
                <h1 className="haircuts-div-c-h1">PEINADOS & MAQUILLAJE</h1>

                    <h1 className="haircuts-div-c-h2">
                        Tus maquillajes y peinados para toda ocacion, con productos de primera calidad
                    </h1>

                    <p className="haircuts-div-c-p">
                        El equipo de Anys Hair Studio, cuenta con certificados internacionales tanto como en depilados, peinado para todo tipo de eventos
                        con las tendencias que estan a la moda, como maquillaje, utilizamos productos de primera calidad, siempre las mejores marcas
                    </p>


                    <h3 className="haircuts-div-c-h3">CEJAS & MAQUILLAJE</h3>

                    <div className="haircuts-div-c-menu">
                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Permanente de pestañas</h1>
                            <h1>Desde $40 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Planchado de cejas</h1>
                            <h1>Desde $40 </h1>
                        </div>  

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Maquillaje</h1>
                            <h1>Desde $50 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Peinado para eventos</h1>
                            <h1>Desde $50 </h1>
                        </div>

                    </div>

                    <p className="haircuts-div-c-p-wedding">
                        Los precios varian dependiendo del estilo. Realiza tu reservacion para atenderte mejor
                    </p>
        </>
     );
}
 
export default FacialsPerms;