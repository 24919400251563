import React from 'react';

import ImagenPrincipal from '../paginaP/imagen-principal'
import ProductosPrincipales from '../paginaP/product-principales'
import AnysHairPrincipal from '../paginaP/anys-hair-team'
import NuestrasMarcas from '../paginaP/marcas-principal'
import TresProductos from '../paginaP/TresPrincipales/tres-productos'


const Home = () => {
    return ( 
        <>
            <ImagenPrincipal />
            <ProductosPrincipales />
            <AnysHairPrincipal />
            <TresProductos />
            <NuestrasMarcas />
        
        </>
     );
}
 
export default Home;