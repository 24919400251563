import React from 'react';


const ServicesColors = () => {
    return ( 
        <>
                <h1 className="haircuts-div-c-h1">TINTES</h1>

                    <h1 className="haircuts-div-c-h2">
                        Nuestros artistas pueden manejar todo tipo de color, realizando espectaculares transformaciones
                    </h1>
                    {/* <p className="haircuts-div-c-p">
                        Our hair color team specializes in different techniques to give you your dream hair color. 
                        These color artists are here to listen to you and help you achieve your color goals. 
                        They are experts in varied approaches to give you low maintenance highlights, bolder colors, lived-in color, platinum white,
                        balayage, & multi-dimensional hair color.
                    </p> */}

                    <p className="haircuts-div-c-p">
                        Nuestro equipo se especializa en diferentes tecnicas, para darle a tu pelo un color inimaginable.
                        Estos artitas escuchan lo que tu queres y te ayudan a alcanzar el color de tus sueños.h1
                        Desde Tintes completos, tinte y rayos, hasta balagage.
                    </p>


                    <h3 className="haircuts-div-c-h3">PRECIO DE TINTES</h3>

                    <div className="haircuts-div-c-menu">
                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Tinte Completo</h1>
                            <h1>Desde $70 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Tinte & Rayos (cabello mediano)</h1>
                            <h1>Desde $125 </h1>
                        </div>  

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Tinte & Rayos (cabello largo)</h1>
                            <h1>Desde $150 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Balayage</h1>
                            <h1>Desde $200 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Permanentes</h1>
                            <h1>Desde $100 </h1>
                        </div>

                    </div>

                    <p className="haircuts-div-c-p-wedding">
                        Los precios varian dependiendo del tipo de cabello a tratar
                    </p>
        </>
     );
}
 
export default ServicesColors;