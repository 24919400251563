import React from 'react';


const HairCutsP1 = () => {
    return ( 
        <>
            <h1 className="haircuts-div-a-h1">hair cutting</h1>
            
            <h1 className="haircuts-div-a-h2">
                Anys Hair Studio trata de traer tu cabello de nuevo a la vida, nos especializamos en mantener tu cabello saludable
            </h1>

            {/* <p className="haircuts-div-a-p">
                Our cutting team is capable of working with all hair types. They will recommend the style that suits the client 
                depending on their particular needs, face shape & lifestyle. We honor your transformations big or small, inside & out. 
                We listen to you, we give feedback & together we come up with the best style for you. We not only cut hair, 
                we are passionate about how we do it. We specialize in dry cutting & done/undone styling, from full hair makeovers 
                to bang trims as well as our signature hair dusting, barbering, notching, internal layers, de-bulking, point cutting & hair carving.
            </p> */}

            <p className="haircuts-div-a-p">
                Nuestro equipo es capaz de trabajar con todos los estilos de cabellos. Ellos te recomendaran el estilo que mejor se asemeja a tu rostro
                dependiendo de sus necesidades particulares. Sera un honor transformales su cabello, ya sea que tenga un cabello corto o largo.
                Los escuchamos & juntos podemos hacer un cambio espectacular en su cabello. Nosotros no solo cortamos el cabello,
                Somos apasionados en lo que hacemos.
            </p>

            <h3 className="haircuts-div-a-h3">CORTES & ALISADOS</h3>

            <div className="haircuts-div-a-menu">
                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Corte de Cabello</h1>
                    <h1>Desde $15 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Lavado con ampolleta</h1>
                    <h1>Desde $20 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Secado de Cabello</h1>
                    <h1>Desde $30 </h1>
                </div>



                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Alisado con Keratina</h1>
                    <h1>$100 - $300 </h1>
                </div>  

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Alisado Japones</h1>
                    <h1>$100 - $200 </h1>
                </div>

            </div>

            <p className="haircuts-div-a-p-wedding">
                Los precios varian dependiendo del tipo de cabello a tratar
            </p>

            <h3 className="haircuts-div-a-h3">TRATAMIENTOS</h3>
            <div className="haircuts-div-a-menu">
                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Tratamiendo de cabello con células</h1>
                    <h1>Desde $30 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Tratamiento en crema intensivo</h1>
                    <h1>Desde $25 </h1>
                </div>  

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Permamentes</h1>
                    <h1>Desde $100 </h1>
                </div>

            </div>
            
     </>
     );
}
 
export default HairCutsP1;