import React from 'react';

import facial1 from '../../../Images/Facial_1.jpg'
import facial2 from '../../../Images/Facial_2.jpg'

const FacialImages = () => {
    return ( 
        <div className="imagenes-modelos-haircut">
            <img src={facial1} alt="modeloP1" className="imagenes-modelos-haircut-p1" />
            <img src={facial2} alt="modeloP1" className="imagenes-modelos-haircut-p1" />
        </div>
     );
}
 
export default FacialImages;