import React from 'react';

import MenuServicesHeader from '../../Services/Menu/HeaderMenu'
import MenudeProductos from '../../Services/Menu/ProductosMenu'

import Helmet from 'react-helmet'


const Services = () => {
    return ( 
        <>
        <Helmet>
            <title>Nuestros Servicios</title>
            <meta name="description" content="Descubre nuestros servicios. Anys Hair Studios" />
        </Helmet>
            <MenuServicesHeader />
            <MenudeProductos />
        </>
     );
}
 
export default Services;