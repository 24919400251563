import React from 'react';

import TresFaciales from './tres-facials'
import TresPedicure from './tres-pedicure'
import TresHaircut from './tres-haircut'

const TresProductos = () => {
    return ( 
            <div className="tres-productos-main">
                <div className="tres-productos-main-divs">
                    <div className="tres-productos-main-divs-hair">
                        <TresHaircut />
                    </div>
                    <div className="tres-productos-main-divs-pedi">
                        <TresPedicure />
                    </div>
                    <div className="tres-productos-main-divs-facials">
                        <TresFaciales />
                    </div>
                </div>
            </div>
     );
}
 
export default TresProductos;