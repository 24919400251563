import React from 'react'
import Helmet from 'react-helmet'
import NavBar from './Menu/MenuJS/NabVar'
import Footer from '../Components/Footer/footer'
import FooterTwo from '../Components/Footer/footerTwo'



const Layout = ({children}) => {
    return ( 
        <>
            <Helmet>
                <title>Anys Hair Studio</title>
                <meta 
                    name='description'
                    content="Salon de belleza, Los Angeles. Anys Hair Studio"
                />

                <meta name="keywords" content="Salon de belleza, Cortes, Tintes & Tintes, Pedicure & Manicure, Faciales & Permamentes " />

                <link href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.4/css/all.css" 
                        integrity="sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm" 
                        crossorigin="anonymous" />
            </Helmet>

            <NavBar />
                {children}

            <Footer />
            <FooterTwo />


        </>
     );
}
 
export default Layout;