import React from 'react';

import imagenchida from '../../Images/ppestatica.JPG'

const ImagenEstaticaPrincipal = () => {
    return ( 
        <img src={imagenchida} alt="imagen principal" className="imagen-estatica-principal" />
     );
}
 
export default ImagenEstaticaPrincipal;