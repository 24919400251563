import React from 'react';
import ContactP1 from '../ContactUs/contactus'
// import ContactUsForm from '../ContactUs/form-contact'

const ContactUs = () => {
    return ( 
        <>
            <ContactP1 />

                <div className="div-contact">
                    {/* <ContactUsForm /> */}
                </div>
        </>
     );
}
 
export default ContactUs;