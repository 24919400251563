import React from 'react';

import marcas1 from '../../Images/marcas/morfose-logo.png'
import marcas2 from '../../Images/marcas/opi-logo.png'
import marcas3 from '../../Images/marcas/salerm-logo.png'
// import marcas4 from '../../Images/marcas/tec-italy-logo.png'


const NuestrasMarcas = () => {
    return ( 
        <>
            <div className="marcas-header">
                <div className="marcas-logos">
                    <img src={marcas1} alt="logo morfose" className="size-marcas" />
                    <img src={marcas2} alt="logo opi" className="size-marcas-opi" />
                    <img src={marcas3} alt="logo salerm" className="size-marca-salem" />
                    {/* <img src={marcas4} alt="logo tec" className="size-marcas" /> */}

                </div>
            </div>
        </>
     );
}
 
export default NuestrasMarcas;