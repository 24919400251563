import React from 'react';
import logo_img from '../../Images/logos/imessage_logo.png'
import logo_wha from '../../Images/logos/logo_whatsapp.png'
import { Link } from 'react-router-dom'
import MapaAnys from './mapa'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'

const ContactP1 = () => {
    return ( 
        <div className="contact-form">
            <div className="contact-form-div">
                <div className="contact-form-div-a">
                    <h1 className="contact-form-div-a-header">
                        Contactanos
                    </h1>

                    <p className="contact-form-div-a-parag">
                        Contactanos, nuestro servicio al cliente esta siempre disponibe, pueden hacer una cita, preguntas mas 
                        especificas sobre nuestros servicios y productos que ofrecemos. Puedes hacerlo por los medios que te proporcionamos
                        en la parte inferior. Esperamos escuchar pronto sobre ti!
                    </p>


                    <h2 className="contact-form-div-a-email">
                        email: <a className="mail-name-contact-us" href="mailto:info@anyshairstudio.com"> info@anyshairstudio.com</a>
                    </h2>
                    <h2 className="contact-form-div-a-phone">
                        Phone: <a className="phone-number-contact-us" href="tel:323-830-7523">323-830-7523</a>
                    </h2>

                    <div className="contact-form-div-a-imessage">
                        <div className="contact-form-div-a-imessage-a">
                            <img src={logo_img} alt="logo imessage" className="imagen-imessage" />
                            <div className="contact-form-div-a-imessage-endless">
                                Escribenos en iMessage
                            </div>
                        </div>
                        <div className="contact-form-div-a-imessage-b">
                            Si cuentas con un dispositivo Apple, puedes escribirnos por iMessage, nos tomara minutos contestarte.
                        </div>
                    </div>

                    <div className="contact-form-div-a-imessage">
                        <div className="contact-form-div-a-imessage-a">
                            <img src={logo_wha} alt="logo imessage" className="imagen-wha" />
                            <div className="contact-form-div-a-imessage-endless">
                                Escribenos en Whatsapp
                            </div>
                        </div>
                        <div className="contact-form-div-a-imessage-b">
                            Tambien contamos con la aplicacion de Whatsapp, puedes escribirnos y te contestaremos en minutos
                        </div>
                    </div>



                    {/* imessage://novaartssalon@icloud.com */}
                    

                </div>
                <div className="contact-form-div-b">
                    <h1 className="contact-form-div-b-header">
                        Visitanos
                    </h1> 
                    <p className="contact-form-div-b-parag">
                        Puedes encontrarnos en
                    </p>
                    <h2 className="contact-form-div-b-address">
                        1555 E Florence Ave, Los Angeles, CA 90001
                    </h2>

                    <div className="contact-form-div-b-mapa">
                        <h1 className="contact-form-div-b-mapa-google-maps">
                            <Link
                                to='https://g.page/Anys-Hair-Studio?share'
                                className="anys-hair-goog-link"
                            > <FontAwesomeIcon icon={faMapMarkedAlt} /> Haz click aqui para abrir Google Maps
                            </Link>
                        <div className="contact-form-div-b-mapa-google-maps-mapa">
                            <MapaAnys />
                        </div>
                        </h1>
                    </div>

                </div>
            </div>
        </div>
     );
}
 
export default ContactP1;