import React from 'react';

import modelop1 from '../../../Images/servicesp1.png'
import modelop2 from '../../../Images/servicesp2.png'

const ImagenesServicios = () => {
    return ( 
        <div className="imagenes-modelos-haircut">
            <img src={modelop1} alt="modeloP1" className="imagenes-modelos-haircut-p1" />
            <img src={modelop2} alt="modeloP1" className="imagenes-modelos-haircut-p1" />
        </div>
     );
}
 
export default ImagenesServicios;