import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Layout from './Components/layout'

import Home from './Components/pages/Home'
import AboutUs from './Components/pages/about-us'
import FindUs from './Components/pages/find-us'
import ContactUs from './Components/pages/contact-us'
import OurTeam from './Components/pages/our-team'
import Services from './Components/pages/Services/services'

import HairCutColor from './Components/pages/Services/haircut-color-change'
import PediManicure from './Components/pages/Services/pedicure-manicure'
import PermsFacials from './Components/pages/Services/facials-perms'

import ScrollToTop from '../src/Components/scrolltotop'

import '../src/sass/main.scss'

function App() {
  return (
    <Router>

      <Layout>
        <Switch>
        <ScrollToTop>
          <Route path='/' exact component= {Home}/>
          <Route path='/about-us' component={AboutUs} />
          <Route path='/find-us' component={FindUs} />
          <Route path='/contact-us' component={ContactUs} />
          <Route path='/our-team' component={OurTeam} />
          <Route path='/services' component={Services} />
          <Route path='/haircut-color-change' component={HairCutColor} />
          <Route path='/pedicure-manicure' component={PediManicure} />
          <Route path='/facials-perms' component={PermsFacials} />
        </ScrollToTop>
    </Switch>

      </Layout>
    
    
    </Router>
  );
}

export default App;
