import React from 'react';

import PedicureHeader from './pedicure-header'
import PedicureImages from './pedicure-images'
import PedicureManicure from './pedicure-manicure'
import PedicurePedicure from './pedicure-pedicure'



const ManicurePedicure = () => {
    return ( 
        <>
            <PedicureHeader />
                <div className="haircuts-header">
                    <div className="haircuts-div">
                        <div className="haircuts-div-a">
                            <PedicurePedicure />
                        </div>

                        <div className="haircuts-div-b">
                            <PedicureImages />
                        </div>                

                        <div className="haircuts-div-c">
                            <PedicureManicure />
                        </div>      
                    </div>
                </div>
        </>
     );
}
 
export default ManicurePedicure;