import React from 'react';


const PedicureManicure = () => {
    return ( 
        <>
                <h1 className="haircuts-div-c-h1">COLORES & DISEños</h1>

                    <h1 className="haircuts-div-c-h2">
                        En Anys Hair Studio conocemos todas las tecnicas y tendencias, las cuales pueden satisfacer todas tus necesidades
                    </h1>
                    {/* <p className="haircuts-div-c-p">
                        Our hair color team specializes in different techniques to give you your dream hair color. 
                        These color artists are here to listen to you and help you achieve your color goals. 
                        They are experts in varied approaches to give you low maintenance highlights, bolder colors, lived-in color, platinum white,
                        balayage, & multi-dimensional hair color.
                    </p> */}

                    <p className="haircuts-div-c-p">
                        Nuestro equipo se especializa en diferentes tecnicas, para darle a tu pelo un color inimaginable.
                        Estos artitas escuchan lo que tu queres y te ayudan a alcanzar el color de tus sueños.h1
                        Desde Tintes completos, tinte y rayos, hasta balagage.
                    </p>


                    <h3 className="haircuts-div-c-h3">COLORES & ESTILOS</h3>

                    <div className="haircuts-div-c-menu">
                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Uñas decoradas</h1>
                            <h1>Desde $5 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Uñas poligel por pie</h1>
                            <h1>Desde $5 </h1>
                        </div>  

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Maquillaje uñas french</h1>
                            <h1>Desde $5 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Cambio de color manos o pies</h1>
                            <h1>Desde $15 </h1>
                        </div>

                        <div className="haircuts-div-c-menu-1">
                            <h1 className="haircuts-change-color">Baño de acrilico en uñas naturales</h1>
                            <h1>Desde $35 </h1>
                        </div>

                    </div>

                    <p className="haircuts-div-c-p-wedding">
                        Los precios varian dependiendo del estilo y colores a aplicar
                    </p>
        </>
     );
}
 
export default PedicureManicure;