import React from 'react';
import imagen_logo from '../../Images/logos/BLANCO.png'

// import { Link } from 'react-router-dom'

const AnysHairPrincipal = () => {
    return ( 
        <>
            <div className="anys-team-container">

                <div className="anys-team-container-sides">
                    <div className="anys-team-container-sides-a">
                       <img src={imagen_logo} alt="logo" className="logo-imagen" /> 
                    </div>
                </div>

                <div className="anys-team-container-sides">
                    <div className="anys-team-container-sides-b"> 
                        <h2 className="anys-team_header">
                            Nuestro Equipo
                        </h2>
                        <p className="anys-team_header_secon">
                        Así como las estrellas se fusionan para volverse más brillantes, aceptamos el cambio y la reinvención de una manera creativa, ayudando a las personas a brillar.
                        Conoce nuestro increible equipo.                            
                        </p>

                        {/* <Link
                            to='/our-team'
                            className="anys-team_btn"
                        >
                            Conocenos
                        </Link> */}


                    </div>
                </div>

            </div>
        </>

     );
}
 
export default AnysHairPrincipal;