import React from 'react';


const PedicurePedicure = () => {
    return ( 
        <>
            <h1 className="haircuts-div-a-h1">Pedicure & Manicure</h1>
            
            <h1 className="haircuts-div-a-h2">
                Anys Hair Studio tiene todos los tratamientos que tus manos y pies necesitan
            </h1>

            {/* <p className="haircuts-div-a-p">
                Our cutting team is capable of working with all hair types. They will recommend the style that suits the client 
                depending on their particular needs, face shape & lifestyle. We honor your transformations big or small, inside & out. 
                We listen to you, we give feedback & together we come up with the best style for you. We not only cut hair, 
                we are passionate about how we do it. We specialize in dry cutting & done/undone styling, from full hair makeovers 
                to bang trims as well as our signature hair dusting, barbering, notching, internal layers, de-bulking, point cutting & hair carving.
            </p> */}


            <p className="haircuts-div-a-p">
                Nuestro equipo en Anys Hair Studio, esta capacitado con las ultimas tendencias en el cuidado de uñas, por ello realizados 
                un pedicure y manicure con los equipos mas avanzados, con las ultimas tendencias de moda. Sera un honor para nosotros 
                transformales para esas ocaciones especiales, tanto en diseños basicos como aquellos que necesitan mas elaboracion,
                que esperas, contactenos.
            </p>

            <h3 className="haircuts-div-a-h3">MANICURE & PEDICURE</h3>

            <div className="haircuts-div-a-menu">
                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Manicure</h1>
                    <h1>Desde $25 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Pedicure</h1>
                    <h1>Desde $30  </h1>
                </div>  

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Retiro de uñas</h1>
                    <h1>Desde $15 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Uñas Gel</h1>
                    <h1>Desde $40 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Uñas acrilicas</h1>
                    <h1>Desde $40 </h1>
                </div>
            </div>

            <p className="haircuts-div-a-p-wedding">
                Para mas informacion puedes contactarnos para hacer tu reservacion
            </p>

            {/* <h3 className="haircuts-div-a-h3">COLORES</h3>
            <div className="haircuts-div-a-menu">
                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Uñas decoradas</h1>
                    <h1>Desde $5 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Uñas poligel por pie</h1>
                    <h1>Desde $5 </h1>
                </div>  

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Maquillaje uñas french</h1>
                    <h1>Desde $5 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Cambio de color manos o pies</h1>
                    <h1>Desde $15 </h1>
                </div>

                <div className="haircuts-div-a-menu-1">
                    <h1 className="haircuts-change-color">Baño de acrilico en uñas naturales</h1>
                    <h1>Desde $35 </h1>
                </div>

            </div> */}
            
     </>
     );
}
 
export default PedicurePedicure;