import React from 'react';

import nails1 from '../../../Images/Page_Nails1.jpg'
import nails2 from '../../../Images/Page_Nails2.jpg'

const PedicureImages = () => {
    return ( 
        <div className="imagenes-modelos-haircut">
            <img src={nails1} alt="modeloP1" className="imagenes-modelos-haircut-p1" />
            <img src={nails2} alt="modeloP1" className="imagenes-modelos-haircut-p1" />
        </div>
     );
}
 
export default PedicureImages;