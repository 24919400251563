import React from 'react';

import HairCutsP1 from '../HairCuts/Services-Haircuts'
import ImagenesServicios from '../HairCuts/Services-images'
import ServicesColors from '../HairCuts/Services-Colors'

const ServicesHaircut = () => {
    return ( 
        <>
        <div className="haircuts-header">
            <div className="haircuts-div">
                <div className="haircuts-div-a">
                    <HairCutsP1 />
                </div>

                <div className="haircuts-div-b">
                    <ImagenesServicios />
                </div>                

                <div className="haircuts-div-c">
                    <ServicesColors />
                </div>
                
            </div>
        </div>
        </>
     );
}
 
export default ServicesHaircut;