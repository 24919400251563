import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons'

const TresHaircut = () => {
    return ( 

            <div className="card-haircut">
                <FontAwesomeIcon icon={faStarOfLife}/>
                <div className="card-haircut-header">
                    Anys
                </div>
                <div className="card-haircut-header-p">
                    Haircut and Color Change
                </div>
            </div>
     );
}
 
export default TresHaircut;