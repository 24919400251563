import React from 'react';
import { Link } from 'react-router-dom';

const ProductosPrincipales = () => {
    return ( 
        <>
            <div className="menu-principal">
                <div className="menu-principal-text-">
                    <h1 className="menu-principal-text__h1">
                        Anys Hair Studio
                    </h1>

                    <p className="menu-principal-text__p1">DESCUBRE LOS INCREIBLES SERVICIOS CON LOS QUE CUENTA ANYS HAIR STUDIO, TENEMOS PARA TODA OCACION QUE NECESITES   </p>
                    
                    <Link
                        to='/services'
                        className="anys-team_btn btn-for-services"
                    >
                        Nuestros Servicios
                    </Link>

                </div>
            </div>
       
        </>

     );
}
 
export default ProductosPrincipales;