import React from 'react';

import FacialFacials from './facials-facials'
import FacialsHeader from './facials-header'
import FacialImages from './facials-images'
import FacialsPerms from './facials-perms'



const FacialnPerms = () => {
    return ( 
        <>
            <FacialsHeader />
                <div className="haircuts-header">
                    <div className="haircuts-div">
                        <div className="haircuts-div-a">
                            <FacialFacials />
                        </div>

                        <div className="haircuts-div-b">
                            <FacialImages />
                        </div>                

                        <div className="haircuts-div-c">
                            <FacialsPerms />
                        </div>      
                    </div>
                </div>
        </>
     );
}
 
export default FacialnPerms;