import React from 'react';
import logo_rosado from '../../Images/logos/NEGRO.png'

const Footer = () => {
    return ( 
        <footer className="footer-principal">

            <div className="footer-principal-div">

                <div class="footer-principal-div-1">
                    <img src={logo_rosado} alt="logo rosado" className="logo-footer" />
                </div>

                {/* <div class="footer-principal-div-2">
                    <h1>Hola soy el Div 2</h1>
                </div> */}

                <div class="footer-principal-div-3">
                    <h1 className="footer-principal-div-3-header">
                        Anys Hair Studio
                    </h1>
                    <h3 className="footer-principal-div-3-p">
                        Somos un Salon Studio, nos especializamos en Cabello, Pedicure, Manicure, Faciales, Permamentes y mas!
                    </h3>
                    <h3 className="footer-principal-div-3-phone">
                        Phone:  
                        <a className="phone-number" href="tel:323-830-7523">323-830-7523</a>
                    </h3>
                    <h3 className="footer-principal-div-3-email">
                        Email: 
                        <a className="mail-name" href="mailto:info@anyshairstudio.com"> info@anyshairstudio.com</a>
                    </h3>

                </div>

                <div className="footer-principal-div-4">
                    <h1 className="footer-principal-div-4-header">Horario del Salon</h1>

                    <h3 className="footer-principal-div-4-hora-1">
                        Domingo cerrado
                    </h3>
                    <h3 className="footer-principal-div-4-hora-2">
                        LUN - SAB - 9:00 AM - 6:00 PM
                    </h3>
                </div>
            </div>
            
        </footer>
     );
}
 
export default Footer;