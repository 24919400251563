import React from 'react';

import ServicesHeader from '../../../Components/Services/HairCuts/Services-Header'
import ServicesHaircut from '../../../Components/Services/HairCuts/Services-haircut'

import Helmet from 'react-helmet'

const HairCutColor = () => {
    return ( 
        <>
        <Helmet>
            <title>Cortes & Tintes</title>
            <meta name="description" content="Descubre nuestros Cortes y Tintes. Anys Hair Studios" />
        </Helmet>
            <ServicesHeader />
            <ServicesHaircut />
         </>
     );
}
 
export default HairCutColor;