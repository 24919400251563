import React from 'react';

const FooterTwo = () => {

    const year = new Date().getFullYear();

    return ( 
        <div className="footertwo-main">
            <h1 className="footertwo-main-text">
                Anys Hair Studio - All Rights Reserved - {year} &copy; by. 
            </h1>
        </div>
     );
}
 
export default FooterTwo;