import React, { useState } from 'react';
// import { BButton } from './Button';
import { Link } from 'react-router-dom';
import '../Menucss/NavBar.scss'
import Dropdown from '../MenuJS/DropDown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <>
      <nav className='navbar'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          Anys Hair Studio
          {/* <i className='fab fa-firstdraft' /> */}
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          {/* <i className={click ? 'fas fa-times' : 'fas fa-bars'} /> */}
          <FontAwesomeIcon icon={faBars} className="icon-menu"/>
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to='/services'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Servicios
              
              {/* <i className='fas fa-caret-down' /> */}

            </Link>
            {dropdown && <Dropdown />}
          </li>
          {/* <li className='nav-item'>
            <Link
              to='/products'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Productos
            </Link>
          </li> */}
          <li className='nav-item'>
            <Link
              to='/contact-us'
              className="boton-contactanos nav-links"
              onClick={closeMobileMenu}
            >
              Contactanos
            </Link>
          </li>
          {/* <li>
            <Link
              to='/contact-us'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
              Contact Us 2
            </Link>
          </li> */}
        </ul>
        {/* <BButton /> */}
      </nav>
    </>
  );
}

export default Navbar;