import React from 'react';
import ManicurePedicure from '../../../Components/Services/Pedicure/pedicure-and-manicure'
import Helmet from 'react-helmet'

const PediManicure = () => {
    return ( 
        <>
            <Helmet>
                <title>Pedicure & Manicure</title>
                <meta name="description" content="Descubre lo nuevo en Pedicure & Manicure. Anys Hair Studios" />
            </Helmet>
        <ManicurePedicure />
        </>
     );
}
 
export default PediManicure;