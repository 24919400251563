import React from 'react';

const FacialFacials = () => {
    return ( 
        <>
        <h1 className="haircuts-div-a-h1">Facials & Perms</h1>
        
        <h1 className="haircuts-div-a-h2">
            Anys Hair Studio cuenta con depilacion y maquillaje para toda ocacione
        </h1>

        {/* <p className="haircuts-div-a-p">
            Nuestro equipo en Anys Hair Studio, esta capacitado con las ultimas tendencias en el cuidado de uñas, por ello realizados 
            un pedicure y manicure con los equipos mas avanzados, con las ultimas tendencias de moda. Sera un honor para nosotros 
            transformales para esas ocaciones especiales, tanto en diseños basicos como aquellos que necesitan mas elaboracion,
            que esperas, contactenos.
        </p> */}

        <p className="haircuts-div-a-p">
            El equipo de Anys Hair Studio, cuenta con herramientas para realizar repilacion de facial, para dejarte esas cejas
            como siempre has querido, esas depilaciones WAX que siempre hacen la diferencia, ese maquillaje para 
            las ocaciones especiales que tanto esperamos, utilizando maquillaje de primera calidad, como siempre nos caracterizamos
        </p>

        <h3 className="haircuts-div-a-h3">Faciales</h3>

        <div className="haircuts-div-a-menu">
            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Facial</h1>
                <h1>Desde $40 </h1>
            </div>

            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Depilado WAX ceja</h1>
                <h1>Desde $8  </h1>
            </div>  

            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Depilado Facial</h1>
                <h1>Desde $35 </h1>
            </div>

            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Depilado pierna completa</h1>
                <h1>Desde $40 </h1>
            </div>
        </div>

        <p className="haircuts-div-a-p-wedding">
            Para mas informacion puedes contactarnos para hacer tu reservacion
        </p>

        {/* <h3 className="haircuts-div-a-h3">COLORES</h3>
        <div className="haircuts-div-a-menu">
            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Uñas decoradas</h1>
                <h1>Desde $5 </h1>
            </div>

            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Uñas poligel por pie</h1>
                <h1>Desde $5 </h1>
            </div>  

            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Maquillaje uñas french</h1>
                <h1>Desde $5 </h1>
            </div>

            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Cambio de color manos o pies</h1>
                <h1>Desde $15 </h1>
            </div>

            <div className="haircuts-div-a-menu-1">
                <h1 className="haircuts-change-color">Baño de acrilico en uñas naturales</h1>
                <h1>Desde $35 </h1>
            </div>

        </div> */}
        
 </>
     );
}
 
export default FacialFacials;