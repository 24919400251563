import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';


const AnyReactComponent = ({ text }) => <div>{text}</div>;

class MapaAnys extends Component {
    static defaultProps = {
      center: {
        lat: 33.9712877,
        lng: -118.2872619
      },
      zoom: 12
    };
  
    render() {
      return (
        // Important! Always set the container height explicitly
        <div className="mapa-contact-us" 
        // style={{ height: '40vh', width: '100%', background-color: 'coral' }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyCFlmbfxEXvtlczMMJRHjWAZGlgmkyjKFA"}}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <AnyReactComponent className="mapa-contact-us-text"
              lat={33.978921839640265}
              lng={-118.24621596007137}
              text= "Anys Hair Studio"
            />

          </GoogleMapReact>
        </div>
      );
    }
  }
  
  export default MapaAnys;
