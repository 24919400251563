import React from 'react';

import { Link } from 'react-router-dom';

const MenudeProductos = () => {
    return ( 
        <> 
            <div className="menu-productos-header">
                <div className="menu-productos-header_div">
                    <div className="menu-productos-header_div__a">

                        <div className="menu-productos-header_div__a_h">
                            <h1 className="menu-productos-header_div__a_h1">
                                Cortes & tintes
                            </h1>
                        </div>

                        <div className="menu-productos-header_div__a_p">
                            <p className="menu-productos-header_div__a_p1">
                                En esta seccion encontraras todo lo relacionado con cortes de cabello y tintes
                            </p>

                            <Link
                                to='/haircut-color-change'
                                className="menu-services_btn"
                            >
                            Conoce mas</Link>

                        </div>
                    </div>



                    <div className="menu-productos-header_div__b">
                        <div className="menu-productos-header_div__b__1">
                            <h1 className="menu-productos-header_div__b__1-h1">
                                Pedicure & Manicure
                            </h1>
                            
                            <p className="menu-productos-header_div__b__1-p">
                                La seccion para nuestras manos y pies, para que siempre luzcas hermosa
                            </p>

                            <Link
                                to='/pedicure-manicure'
                                className="menu-servicestwo_btn menu-servicestwo_size"
                            >
                            Conoce mas</Link>
                            

                        </div>

                        <div className="menu-productos-header_div__b__2">
                            <h1 className="menu-productos-header_div__b__1-h1">
                                    Faciales & Permanentes
                                </h1>
                                
                                <p className="menu-productos-header_div__b__1-p">
                                    Tienes un evento? tenemos maquillajes, peinados y mucho mas en esta seccion
                                </p>

                                <Link
                                to='/facials-perms'
                                className="menu-servicestwo_btnn menu-servicestwo_size"
                                >
                                Conoce mas</Link>
                        </div>
                    </div>

                </div>
            </div>
        </>
     );
}
 
export default MenudeProductos;