import React from 'react';

import FacialnPerms from '../../../Components/Services/Facials/facials-and-perms'

import Helmet from 'react-helmet'

const PermsFacials = () => {
    return ( 
        <>
        <Helmet>
            <title>Faciales & Permanentes</title>
            <meta name="description" content="Descubre nuestros Faciales y Permamentes. Anys Hair Studios" />
        </Helmet>

        <FacialnPerms />
        </>
     );
}
 
export default PermsFacials;