import React from 'react';

const OurTeam = () => {
    return ( 
        <h1>
            Hola desde Our Team
        </h1>
     );
}
 
export default OurTeam;