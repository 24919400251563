import React from 'react';
// import imagen_prueba from '../../Images/prueba/img_avatar.png'
// import test1 from '../../Images/test1.jpg'

// import CarouselContainer from '../paginaP/SlideShowTest'
// import foto_principal from '../../Images/principal.jpg'

import ImagenEstaticaPrincipal from './imagen-estatica'


const ImagenPrincipal = () => {
	return (
		<>
			<div className="principal-header">
				<div className="principal-header-div">
					<div className="principal-header-div__a">
						<ImagenEstaticaPrincipal />
					</div>

					<div className="principal-header-div__b">
						<div className="principal-header-div__picture">
							<p className="principal-header-div__b__paragra">
								Welcome to Anys Hair Studio
							</p>	
						</div>

						<div className="principal-header-div__b-side">
							<div className="principal-header-div__b-side__b1">
								<p className="principal-header-div__b__paragra">
									Nails
								</p>	
							</div>		
							<div className="principal-header-div__b-side__b2">
							<p className="principal-header-div__b__paragra">
									Facials
								</p>
							</div>		
						</div>			
					</div>
				</div>
			</div>
		</>

	);
}

export default ImagenPrincipal



