import React from 'react';

const MenuServicesHeader = () => {
    return ( 
        <div className="services-page-header">
        <div className="services-page-header-top">
            <h3 className="services-page-header-top-txt-1">
                Nuestros Servicios
            </h3>
        </div>
        <div className="services-page-header-down">
            <p className="services-page-header-down-t2">
                Vamos a realizar los sueños juntos, tenemos increibles servicios para ti.
            </p>
        </div>
    </div>
     );
}
 
export default MenuServicesHeader;